<template>
    <div class="vx-col md:w-full sm:w-auto">
        <div class="vx-row md:w-full mt-1">

            <vx-tooltip class="position-icon" :text="$t('button.off_on')" position="top">
                <vs-switch color="success" class="mt-2" v-model="turnOnOff"></vs-switch>
            </vx-tooltip>

            <vx-tooltip class="position-icon" :text="$t('button.edit')" position="top" v-if="this.status != false">
                <feather-icon icon="EditIcon" class="action-success icon-btn"
                              svgClasses="h-5 w-5 hover:text-primary cursor-pointer"
                              @click="editRecord"/>
            </vx-tooltip>

            <vx-tooltip class="position-icon" :text="$t('button.delete')" position="top">
                <feather-icon icon="TrashIcon" class="action-err icon-btn"
                              svgClasses="h-5 w-5 hover:text-danger cursor-pointer"
                              @click="confirmDeleteRecord"/>
            </vx-tooltip>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CellRendererActions',
    data() {
        return {
            status: true,
        }
    },
    computed: {
        turnOnOff: {
            get() {
                return this.status;
            },
            set() {
                if (this.status) {
                    this.turnOffService()
                } else {
                    this.turnOnService()
                }
            }
        }
    },
    created() {
        this.status = this.params.data.enabled
    },
    methods: {

        editRecord() {
            let branchId = this.params.data.organizationBranchId;
            this.$router.push(`/user/ai-restrictedarea-update?id=${this.params.data.id}${branchId ? `&branch_id=${branchId}` : ''}`).catch(() => {
            })
        },
        confirmDeleteRecord() {
            this.$vs.dialog({
                type: 'confirm',
                color: 'danger',
                title: this.$t('textIntrusion.titleWarning'),
                text:  `${this.$t('textIntrusion.textDelete')}"${this.params.data.name}"?`,
                accept: this.deleteRecord,
                acceptText: this.$t('textIntrusion.acceptText'),
                cancelText: this.$t('textIntrusion.cancelText')
            })
        },

        deleteRecord() {
            this.$vs.loading();
            this.$crm.post(`/ai-restricted/delete/${this.params.data.organizationBranchId}/${this.params.data.id}`).then(() => {
                this.$vs.loading.close();
                return this.showDeleteSuccess();
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },

        showDeleteSuccess() {
            this.$store.commit('UPDATE_AI_RESTRICTED_SUCCESS', true);
            this.$vs.notify({
                color: 'success',
                text: this.$t('textIntrusion.deleteSuccess'),
                iconPack: 'feather',
                icon: 'icon-alert-circle',
            })
        },

        turnOffService() {
            this.$vs.loading()
            this.$crm.post(`/ai-restricted/is-enabled/${this.params.data.id}/false`).then(response => {
                this.$vs.loading.close()
                this.status = false
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                })
            })
        },

        turnOnService() {
            this.$vs.loading()
            this.$crm.post(`/ai-restricted/is-enabled/${this.params.data.id}/true`).then(response => {
                this.$vs.loading.close()
                this.status = true
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                })
            })
        }
    },
}
</script>
