<template>
    <div class="show-img">
        <img :src="this.params.data.imageUrl" class="rounded w-full img-checkin" @click="onclickImg"/>
        <vs-popup :title="isTitle" :active.sync="activePopUpImg">
            <img :src="this.params.data.imageUrl" width="567">
        </vs-popup>
    </div>
</template>
<script>

export default {
    name: 'CellRendererImg',
    data() {
        return {
            activePopUpImg: false,
            isTitle: "Hình ảnh phóng to",
        }
    },
    methods: {
        onclickImg() {
            this.activePopUpImg = true;
        },
    },
}
</script>
<style scoped>

.show-img .img-checkin {
    display: block;
    height: 95px;
    padding: 5px;
    width: 150px !important;
}

.show-img {
    position: relative
}

</style>
