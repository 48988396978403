<template>
    <div id="ai-restricted-area-history">
        <div class="vx-card p-6">
            <div class="vx-col md:w-full flex items-center mb-5">
                <TitleScreen></TitleScreen>
            </div>
            <div class="vx-row">
                <div class="vx-col md:w-1/3 w-full mt-2">
                    <label class="vs-input--label">{{$t('menuIntrusion.branch')}}</label>
                    <v-select class="" v-model="selectedOrganizationBranch" :clearable="false"
                              :options="organizationBranchOptions">
                        <span slot="no-options"> {{ $t('menuOrganization.noOption') }}</span>
                    </v-select>
                </div>
            </div>
            <div class="vx-row">
                <div class="vx-col md:w-1/3 w-full mt-2">
                    <div class="flex-grow">
                        <label class="vs-input--label">{{$t('menuIntrusion.selectCamera')}}</label>
                        <v-select class="" v-model="selectedCamera" :clearable="false"
                                  :options="cameraOptions">
                            <span slot="no-options"> {{ $t('menuOrganization.noOption') }}</span>
                        </v-select>
                    </div>
                </div>
                <div class="vx-col mt-2">
                    <label class="vs-input--label">{{$t('menuIntrusion.time')}}</label>
                    <div class="flex-grow">
                        <date-range-picker
                            ref="picker"
                            :locale-data="{format: 'dd/mm/yyyy' }"
                            :opens="'right'"
                            @update="dateRangePicker"
                            v-model="dateRange">
                        </date-range-picker>

                    </div>
                </div>
                <div class="vx-col md:w-1/3 w-full mt-26">
                    <div class="flex">
                        <vs-button @click="filter" :disabled="!validateForm">{{$t('menuIntrusion.filter')}}</vs-button>
                    </div>
                </div>
            </div>
            <div class="flex flex-wrap items-center mt-5">
                <div class="flex-grow">
                </div>
                <vs-input class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4"
                          v-model="searchQuery"
                          @input="updateSearchQuery" :placeholder="$t('button.search')"/>
            </div>
            <!-- AgGrid Table -->
            <ag-grid-vue style="height: 55vh"
                         ref="agGridTable"
                         :components="components"
                         :gridOptions="gridOptions"
                         class="ag-theme-material w-100 my-4 ag-grid-table"
                         :columnDefs="columnDefs"
                         :defaultColDef="defaultColDef"
                         :rowData="aiRestrictedAreaHistory"
                         :animateRows="true"
                         :pagination="true"
                         :paginationPageSize="paginationPageSize"
                         :onGridReady="onGridReady"
                         :localeText=" {noRowsToShow: this.$t('menuCustomer.noRowsToShow')}"
                         :suppressPaginationPanel="true">
            </ag-grid-vue>

            <div class="vx-row mt-10 items-center">
                <div class="vx-col md:w-1/2">
                    <Pagination
                        :itemList="aiRestrictedAreaHistory"
                        :currentPage="currentPage"
                        :paginationPageSize="paginationPageSize"
                        :gridApi="gridApi"
                    />
                </div>
                <div class="vx-col md:w-1/2">
                    <vs-pagination
                        :total="totalPages"
                        :max="maxPageNumbers"
                        v-model="currentPage"/>
                </div>
            </div>

        </div>
    </div>
</template>
<style scoped>
.vue-daterange-picker .ranges {
    float: right;
    border-left: 1px solid #ddd;
}

.daterangepicker {
    border-left: none !important;
}

.title-page {
    font-size: 2.2rem;
    font-weight: bold;
    display: flex;
}

.text-font {
    opacity: 0.7;
}

.reportrange-text {
    border-radius: 5px;
    height: 38px;
    line-height: 27px;
}

.mt-26 {
    margin-top: 26px;
}
</style>
<script>
import vSelect from 'vue-select'
import {AgGridVue} from "ag-grid-vue"
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import authConfig from '@/../auth_config.json';
import Datepicker from 'vuejs-datepicker';
import moment from 'moment'; // Vuesax
import CellRendererActions from "./cell-renderer/CellRendererActions.vue";
import Pagination from "../../ui-elements/Pagination.vue"
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import CellRendererImg from "../confirmTimeKeeping/cell-renderer/CellRendererImg"
import DateRangePicker from '../../../components/vue2-daterange-picker/components/DateRangePicker.vue'
import TitleScreen from "../../../layouts/main/TitleScreen";

export default {
    name: "AIRestrictedareaHistory",
    components: {
        AgGridVue,
        vSelect,
        // Cell Renderer
        Datepicker,
        CellRendererActions,
        Pagination,
        flatPickr,
        CellRendererImg,
        DateRangePicker,
        TitleScreen
    },
    data() {
        return {
            showImg: false,
            imageUrl: "",
            searchQuery: "",
            maxPageNumbers: 7,
            // AgGrid
            gridApi: null,
            gridOptions: {
                rowHeight: 100
            },
            defaultColDef: {},
            columnDefs: [
                {
                    headerName: 'Camera',
                    field: 'cameraName',
                    width: 150,
                },
                {
                    headerName: this.$t('menuIntrusion.time'),
                    field: 'checkinTime',
                    width: 150,
                },
                {
                    headerName:  this.$t('menuIntrusion.img'),
                    field: 'imageUrl',
                    width: 150,
                    cellRendererFramework: 'CellRendererImg'
                },
            ],
            // Cell Renderer Components
            components: {
                CellRendererActions
            },
            aiRestrictedAreaHistory: [],
            organizationBranchOptions: [],
            selectedOrganizationBranchValue: null,
            cameraOptions: [{value: null, label: this.$t('menuIntrusion.all')}],
            cameraId: null,
            dateRange: {
                endDate: new Date(),
                startDate: (() => {
                    let date = new Date();
                    date.setHours(0, 0, 0);
                    return date
                })()
            },
        }
    },
    created() {
        this.initScreen()
        this.$eventBus.$on('i18n', (event) =>{
            this.columnDefs[1].headerName =  this.$t('menuIntrusion.time');
            this.columnDefs[2].headerName =  this.$t('menuIntrusion.img');
            this.gridOptions.api.setColumnDefs(this.columnDefs);

        })
    },
    watch: {
        '$store.state.windowWidth'(val) {
            if (val <= 1024) {
                this.maxPageNumbers = 5;
            } else {
                this.maxPageNumbers = 7;
            }

            if (val > 1024) {
                this.gridApi.sizeColumnsToFit();
            }
        },
        '$store.state.reduceButton'() {
            setTimeout(() => {
                this.gridApi.sizeColumnsToFit();
            }, 650);
        },
    },
    computed: {
        selectedCamera: {
            get() {
                if (this.cameraOptions.length > 0) {
                    return {
                        label: this.cameraOptions.find(x => x.value == this.cameraId).label,
                        value: this.cameraId
                    }
                }
            },
            set(obj) {
                this.cameraId = obj.value
            }
        },
        paginationPageSize() {
            if (this.gridApi) return this.gridApi.paginationGetPageSize();
            else return 20
        },
        totalPages() {
            if (this.gridApi) return this.gridApi.paginationGetTotalPages();
            else return 0
        },
        currentPage: {
            get() {
                if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
                else return 1
            },
            set(val) {
                this.gridApi.paginationGoToPage(val - 1)
            }
        },
        selectedOrganizationBranch: {
            get() {
                return {
                    label: this.organizationBranchString(this.selectedOrganizationBranchValue),
                    value: this.selectedOrganizationBranchValue
                }
            },
            set(obj) {
                this.selectedOrganizationBranchValue = obj.value
            }
        },
    },
    methods: {
        dateRangePicker: function () {
            this.dateRange.startDate = new Date(this.dateRange.startDate.setHours(0, 0, 0));
            this.dateRange.endDate = new Date(this.dateRange.endDate.setHours(23, 59, 59));
        },
        getCameraByBranch() {
            let that = this;
            let url = '/camera/all';
            that.$vs.loading();
            that.$crm.post(url).then((response) => {
                that.cameraOptions = response.data.map(item => {
                    return {value: item.id, label: item.cameraName}
                });
                that.cameraOptions.unshift({value: null, label: this.$t('menuIntrusion.all')})
                this.filter();
                that.$vs.loading.close();
            }).catch((err) => {
                that.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
        mouseOver: function () {
            this.active = !this.active;
        },
        updateSearchQuery(val) {
            this.gridApi.setQuickFilter(val)
        },
        organizationBranchString(id) {
            let organizationBranch = this.organizationBranchOptions.find(x => x.value === id);
            return (organizationBranch && organizationBranch.label) || this.$t('menuIntrusion.determine');
        },
        validateForm() {
            return !this.errors.any()
        },
        filter() {
            let searchInfo = {
                organizationBranchId: this.selectedOrganizationBranchValue,
                cameraId: this.cameraId,
                startTime: this.dateRange.startDate,
                endTime: this.dateRange.endDate
            }
            let that = this;
            that.$processor.post('/intrusion-detection/find-all', searchInfo).then((response) => {
                that.aiRestrictedAreaHistory = response.data.map(res => {
                    if (res.organizationBranchId != that.selectedOrganizationBranchValue) return;
                    let camera = this.cameraOptions.find(x => x.value == res.cameraId);
                    console.log("camera", camera);
                    res.cameraName = camera ? camera.label : '';
                    return res;
                })
                that.aiRestrictedAreaHistory = that.aiRestrictedAreaHistory.filter(x => x != undefined);
                that.aiRestrictedAreaHistory.forEach(x => {
                    x.checkinTime = x.createdAt ? moment(x.createdAt).format("DD/MM/YYYY HH:mm:ss") : null;
                    if (!x.imageUrl.match('http')) {
                        x.imageUrl = authConfig.processorDomain + "storage/" + x.imageUrl
                    }
                });
                setTimeout(() => {
                        this.gridApi.sizeColumnsToFit()
                    }, 100
                )
                that.$vs.loading.close();
            }).catch((err) => {
                console.log(err)
            });
        },
        onGridReady() {
            if (this.$store.state.windowWidth <= 1024) {
                this.maxPageNumbers = 5;
            } else {
                this.maxPageNumbers = 7;
            }

            if (this.$store.state.windowWidth > 1024) {
                this.gridApi.sizeColumnsToFit();
            }
        },
        initScreen() {
            this.getCameraByBranch();
            let branchList = JSON.parse(localStorage.getItem('branchInfoList') || '[]')
            if (branchList.length == 0) {
                return this.$vs.notify({
                    text: this.$t('menuIntrusion.noBranch'),
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            }
            this.organizationBranchOptions = branchList.map(item => {
                return {value: item.id, label: item.organizationBranchName}
            })
            let branchId = this.$route.query.branch_id;
            let index = 0;
            if (branchId) {
                index = this.organizationBranchOptions.findIndex(item => item.value == branchId)
            }
            this.selectedOrganizationBranch = this.organizationBranchOptions[index > -1 ? index : 0];
        },
    },
    mounted() {
        this.gridApi = this.gridOptions.api;
    },
}
</script>
